import { inputSx } from './sx';
import { From } from '../common/From';
import { DurationInMinutes } from '../common/DurationInMinutes';
import { InlineSelectInput } from '../../../common/components/inputs/InlineSelectInput';
import { InlineNumberInput } from '../../../common/components/inputs/InlineNumberInput';
import { getTimeRangeDurationInHours } from '../../../common/helpers/TimeRangeHelpers';
import { GroupsIcon, GroupSplitIconReadonly, RemoveIcon } from '../../../common/components/icons/ReadonlyIcons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, MenuItem, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { AdditionalHoursForm, ContractAgreementForm, FormAdditionalTimeType } from './mapper';
import { OperatorCheckbox } from '../common/OperatorCheckbox';
import { AdditionalHourType } from './AdditionalHours';

export interface LineProps {
  readonly: boolean;
  lineData: AdditionalHoursForm;
  dateFn: (startsOnNextDay: boolean | null | undefined) => Date;
  operators: { id: string; employeeId: string; name: string }[] | undefined;
  remove: (index: number) => void;
  formPath: `additionalHours.${number}`;
  index: number;
}

const translationKey = 'additionalTimes';

/**
 * @deprecated stop using this reference, use the one in contractAgreement/components/shared.
 * @note Move me to shared folder and remove the deprecated tag
 **/
export const AdditionalHourLine = ({
  readonly,
  lineData,
  operators,
  dateFn,
  remove,
  formPath,
  index,
  control,
  setValue,
}: LineProps & Pick<UseFormReturn<ContractAgreementForm>, 'control' | 'setValue'>) => {
  const { t } = useTranslation('validations');
  const additionalHourTypes = useMemo(() => Object.values(AdditionalHourType), []);

  const [split, setSplit] = useState(false);
  const selectedOperators = useWatch({ control, name: `additionalHours.${index}.employeeIds` });
  const canSplit = useMemo(() => operators?.length !== 1 && !split, [operators, split]);
  const canUnsplit = useMemo(() => !operators?.length || selectedOperators?.length === operators?.length, [operators, selectedOperators]);

  useEffect(() => {
    if (canSplit && !!operators?.length && selectedOperators?.length < operators?.length) {
      setSplit(true);
    }
    // only when mounting, open when there is a split
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDurationInMinutesOnChangeType = useCallback(
    (oldValue: FormAdditionalTimeType, newValue: FormAdditionalTimeType) => {
      if (oldValue !== newValue && (newValue === AdditionalHourType.NoBreakTime || newValue === AdditionalHourType.NoLunchTime)) {
        setValue(`${formPath}.durationInMinutes`, newValue === AdditionalHourType.NoBreakTime ? 15 : 30);
      }
    },
    [formPath, setValue],
  );

  return (
    <React.Fragment key={formPath}>
      <TableRow data-testid='additional-hour-line'>
        <TableCell>
          <Controller
            control={control}
            name={`${formPath}.from`}
            render={({ field: { ref, name, onBlur, onChange, value }, fieldState: { error } }) => (
              <From
                myRef={ref}
                name={name}
                errorMessage={error?.message}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                readonly={readonly}
                dateFn={dateFn}
                nextDay={!!lineData?.startsOnNextDay}
                onToggleNextDay={() => {
                  setValue(`${formPath}.startsOnNextDay`, !lineData?.startsOnNextDay, { shouldValidate: true, shouldDirty: true });
                }}
                durationInMinutes={lineData?.durationInMinutes}
                onDurationInMinutesChange={(_value: number) => {
                  setValue(`${formPath}.durationInMinutes`, _value, { shouldValidate: true, shouldDirty: true });
                }}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <Controller
            control={control}
            name={`${formPath}.durationInMinutes`}
            render={({ field: { ref, name, onBlur, onChange }, fieldState: { error } }) => (
              <DurationInMinutes
                myRef={ref}
                name={name}
                errorMessage={error?.message}
                timeRange={lineData}
                onChange={onChange}
                onBlur={onBlur}
                dateFn={dateFn}
                readonly={readonly}
              />
            )}
          />
        </TableCell>
        <TableCell>
          <Controller
            control={control}
            name={`${formPath}.type`}
            render={({ field: { ref, name, value, onChange, onBlur }, fieldState: { error } }) => (
              <InlineSelectInput
                ref={ref}
                name={name}
                disabled={readonly}
                fullWidth
                sx={{ '& fieldset': { border: 'none' } }}
                error={!!error && !readonly}
                size='small'
                value={value ?? 'undefinedMagicKey'}
                onChange={(event) => {
                  const newValue = event.target.value as AdditionalHourType;
                  handleDurationInMinutesOnChangeType(value, newValue);
                  onChange(newValue);
                  onBlur();
                }}>
                <MenuItem disabled value='undefinedMagicKey' sx={{ display: 'none' }}>
                  {t(`${translationKey}.type.empty`)}
                </MenuItem>
                {additionalHourTypes.map((key) => {
                  return (
                    <MenuItem key={key} value={key} style={inputSx}>
                      {t(`${translationKey}.type.${key}`)}
                    </MenuItem>
                  );
                })}
              </InlineSelectInput>
            )}
          />
        </TableCell>
        <TableCell>
          <Controller
            control={control}
            name={`${formPath}.timeEntryInformation.single`}
            render={({ field: { ref, name, value, onBlur, onChange }, fieldState: { error } }) => (
              <Tooltip disableInteractive title={error?.message} placement='top' arrow>
                <div>
                  <InlineNumberInput
                    ref={ref}
                    name={name}
                    disabled={readonly}
                    value={typeof value === 'number' ? value : undefined}
                    maxDecimal={2}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                  />
                </div>
              </Tooltip>
            )}
          />
        </TableCell>
        <TableCell>
          <Controller
            control={control}
            name={`${formPath}.timeEntryInformation.singleAndAHalf`}
            render={({ field: { ref, name, value, onBlur, onChange }, fieldState: { error } }) => (
              <Tooltip disableInteractive title={error?.message} placement='top' arrow>
                <div>
                  <InlineNumberInput
                    ref={ref}
                    name={name}
                    disabled={readonly}
                    value={typeof value === 'number' ? value : undefined}
                    maxDecimal={2}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                  />
                </div>
              </Tooltip>
            )}
          />
        </TableCell>
        <TableCell>
          <Controller
            control={control}
            name={`${formPath}.timeEntryInformation.double`}
            render={({ field: { ref, name, value, onBlur, onChange }, fieldState: { error } }) => (
              <Tooltip disableInteractive title={error?.message} placement='top' arrow>
                <div>
                  <InlineNumberInput
                    ref={ref}
                    name={name}
                    disabled={readonly}
                    value={typeof value === 'number' ? value : undefined}
                    maxDecimal={2}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                  />
                </div>
              </Tooltip>
            )}
          />
        </TableCell>
        <TableCell align='center'>
          <IconButton
            data-testid={`${formPath}.groupSplit`}
            disabled={readonly || (split ? !canUnsplit : !canSplit)}
            onClick={() => setSplit(!split)}>
            {split ? <GroupSplitIconReadonly readonly={readonly} /> : <GroupsIcon readonly={readonly} />}
          </IconButton>
        </TableCell>
        <TableCell style={{ borderRightWidth: 0 }}>
          <Typography variant='body2' align='center' aria-label={`${formPath}.durationInHours`}>
            {getTimeRangeDurationInHours(lineData)}
          </Typography>
        </TableCell>
        <TableCell colSpan={4} style={{ borderLeftWidth: 0 }}>
          <IconButton color='primary' size='small' disabled={readonly} onClick={() => remove(index)}>
            <RemoveIcon readonly={readonly} fontSize='inherit' />
          </IconButton>
        </TableCell>
      </TableRow>
      {split && (
        <TableRow key={`splitted_${formPath}`}>
          <TableCell colSpan={9}>
            <Stack direction='row' padding={1}>
              {operators?.map((operator, operatorIndex) => {
                return (
                  <Controller
                    control={control}
                    key={operator.id + formPath}
                    name={`${formPath}.employeeIds`}
                    render={({ field: { ref, onBlur, onChange, value: employeeIds }, fieldState: { error } }) => (
                      <OperatorCheckbox
                        data-testid={`${formPath}.employee.${operatorIndex}`}
                        ref={ref}
                        disabled={readonly}
                        errorMessage={error?.message}
                        error={!!error}
                        key={operator.id + formPath}
                        checked={employeeIds.some((id: string) => id === operator.employeeId)}
                        name={operator.name}
                        onChange={(isToAdd) => {
                          const newState = isToAdd
                            ? [...employeeIds, operator.employeeId]
                            : employeeIds.filter((id) => id !== operator.employeeId);
                          onChange(newState);
                          onBlur();
                        }}
                      />
                    )}
                  />
                );
              })}
            </Stack>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
