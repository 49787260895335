import axios from 'axios';
import { DefinedInitialDataOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Path } from '../../paths';
import { AccessoryTypeRecord, GetCodesDeFacturationRecord, WorkForceTypeRecord } from '../models/models';
import { useLoaderData } from 'react-router-dom';
import { PrefacturationLoaderData } from '../loaders/PrefacturationLoader';

export const codesDeFacturationOptions = (): Pick<
  DefinedInitialDataOptions<GetCodesDeFacturationRecord[]>,
  'queryKey' | 'queryFn' | 'gcTime' | 'staleTime'
> => ({
  queryKey: [Path.NextPrefacturationSuffix, Path.CodesDeFacturation],
  queryFn: async () => axios.get<GetCodesDeFacturationRecord[]>(`/${Path.CodesDeFacturation}`).then((response) => response.data),
  staleTime: 1000 * 60 * 2,
});
export const workForceTypesOptions = (): Pick<
  DefinedInitialDataOptions<WorkForceTypeRecord[]>,
  'queryKey' | 'queryFn' | 'gcTime' | 'staleTime'
> => ({
  queryKey: [Path.NextPrefacturationSuffix, 'workforce-types'],
  queryFn: async () => axios.get<WorkForceTypeRecord[]>(`/workforce-types`).then((response) => response.data),
  staleTime: 1000 * 60 * 2,
});
export const accessoryTypeOptions = (): Pick<
  DefinedInitialDataOptions<AccessoryTypeRecord[]>,
  'queryKey' | 'queryFn' | 'gcTime' | 'staleTime'
> => ({
  queryKey: [Path.NextPrefacturationSuffix, 'accessory-types'],
  queryFn: async () => axios.get<AccessoryTypeRecord[]>(`/accessory-type`).then((response) => response.data),
  staleTime: 1000 * 60 * 2,
});

const useGetWorkForceTypes = (): UseQueryResult<WorkForceTypeRecord[], unknown> => {
  const { workForceTypes } = useLoaderData() as PrefacturationLoaderData;
  return useQuery({ ...workForceTypesOptions(), initialData: workForceTypes?.data });
};
const useGetAccessoryType = (): UseQueryResult<AccessoryTypeRecord[], unknown> => {
  const { accessoryTypes } = useLoaderData() as PrefacturationLoaderData;
  return useQuery({ ...accessoryTypeOptions(), initialData: accessoryTypes?.data });
};
const useGetCodesDeFacturation = (): UseQueryResult<GetCodesDeFacturationRecord[], unknown> => {
  const { codesDeFacturation } = useLoaderData() as PrefacturationLoaderData;
  return useQuery({ ...codesDeFacturationOptions(), initialData: codesDeFacturation?.data });
};

export default (): {
  workForceTypes: UseQueryResult<WorkForceTypeRecord[], unknown>;
  codesDeFacturation: UseQueryResult<GetCodesDeFacturationRecord[], unknown>;
  accessoryTypes: UseQueryResult<AccessoryTypeRecord[], unknown>;
} => ({
  codesDeFacturation: useGetCodesDeFacturation(),
  workForceTypes: useGetWorkForceTypes(),
  accessoryTypes: useGetAccessoryType(),
});
