import { ApolloProvider } from '@apollo/client';
import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import Warning from '@mui/icons-material/Warning';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { enUS, frFR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import { setUser as setSentryUser } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FlagProvider, useFlag } from '@unleash/proxy-client-react';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { IConfig } from 'unleash-proxy-client';
import SignoutPage from './SignoutPage';
import { loginRequest } from './authConfig';
import { AbilityProvider } from './authorization/AbilityProvider';
import { loadSchedulerLocales, setSchedulerLocale } from './bryntum/localization/schedulerLocales';
import EmployeeContextProvider from './common/contexts/EmployeeContext';
import { useAxios } from './common/hooks/useAxios';
import useSetupApollo from './common/hooks/useSetupApollo';
import { config, config as env } from './config';
import { DispatchingAlertSnackbar } from './dispatch/components/Snackbar/DispatchingAlertSnackbar';
import { PageLoader } from './layout/components/PageLoader';
import muiTheme from './mui/theme';
import { getRoutes } from './routes';

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false }),
    ],
    release: config.SENTRY_RELEASE,
    environment: config.SENTRY_ENVIRONMENT,
    tracesSampleRate: config.SENTRY_TRACESSAMPLERATE,
    ignoreErrors: ['ChunkLoadError', /^Loading chunk$/],
    replaysSessionSampleRate: config.SENTRY_REPLAYSSESSIONSAMPLERATE,
    replaysOnErrorSampleRate: config.SENTRY_REPLAYSONERRORSAMPLERATE,
  });
}

const featureFlagsConfig: IConfig = {
  url: env.FEATURE_FLAG_HOST,
  clientKey: env.FEATURE_FLAG_CLIENT_KEY,
  refreshInterval: env.FEATURE_FLAG_REFRESH_INTERVAL_SECONDS,
  appName: env.SENTRY_ENVIRONMENT,
  environment: env.SENTRY_ENVIRONMENT,
};

LicenseInfo.setLicenseKey('c30e8717856dafde3eee8b4cffefb87fTz05Mjc1NSxFPTE3NTAzNjEwNzgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
export const appQueryClient = new QueryClient({});
export const App = (): JSX.Element | null => {
  const { accounts: [account] = [], instance, inProgress } = useMsal();
  useAxios(account, instance);

  const userEmail: string = (account?.idTokenClaims?.email ?? account?.idTokenClaims?.emails?.[0] ?? account?.username) as string;

  const [apolloClient, isApolloReady] = useSetupApollo(account, instance);
  const isAuthenticated = useIsAuthenticated();
  const { i18n } = useTranslation('layout');
  const langCode = i18n.resolvedLanguage === 'fr' ? frFR : enUS;
  const themeWithLocale = React.useMemo(() => createTheme(muiTheme, langCode), [langCode]);

  useEffect(() => {
    loadSchedulerLocales();
  }, []);

  useEffect(() => {
    setSchedulerLocale(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      account != null
        ? instance.acquireTokenSilent({ ...loginRequest, account: account }).catch((e) => {
            console.error(e);
          })
        : instance
            .loginRedirect({
              ...loginRequest,
            })
            .catch((e) => {
              console.error(e);
            });
    }
  }, [instance, inProgress, isAuthenticated, account]);

  useEffect(() => {
    if (isAuthenticated) {
      setSentryUser({ email: userEmail });
    }
  }, [isAuthenticated, userEmail]);

  useEffect(() => {
    const handleVitePreloadError = () => window.location.reload();
    window.addEventListener('vite:preloadError', handleVitePreloadError);

    return () => {
      window.removeEventListener('vite:preloadError', handleVitePreloadError);
    };
  }, []);

  if (!isApolloReady) return <PageLoader />;

  return (
    <ThemeProvider theme={themeWithLocale}>
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider client={appQueryClient}>
          <CssBaseline />
          <AuthenticatedTemplate>
            <EmployeeContextProvider>
              <AbilityProvider>
                <FlagProvider config={{ ...featureFlagsConfig, context: { userId: userEmail } }}>
                  <SnackbarProvider
                    maxSnack={4}
                    iconVariant={{
                      error: <Warning />,
                    }}
                    Components={{ dispatchingAlert: DispatchingAlertSnackbar }}>
                    <FeatureFlagRouter />
                  </SnackbarProvider>
                </FlagProvider>
              </AbilityProvider>
            </EmployeeContextProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignoutPage />
          </UnauthenticatedTemplate>
        </QueryClientProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};

const FeatureFlagRouter = (): JSX.Element => {
  const show_dispatch_routes = useFlag('show_dispatch_routes');
  const mock_data_generation_enabled = useFlag('mock_data_generation');
  const show_configuration_routes = useFlag('show_configuration_routes');
  const sentryBrowserRouter = useMemo(
    () =>
      Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
        getRoutes(show_dispatch_routes, show_configuration_routes, mock_data_generation_enabled),
      ),
    [show_dispatch_routes, show_configuration_routes, mock_data_generation_enabled],
  );
  return <RouterProvider router={sentryBrowserRouter} />;
};
