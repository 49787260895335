import { Box, SxProps } from '@mui/material';
import accounting, { CurrencySettings } from 'accounting';
import Cleave from 'cleave.js/react';
import { CSSProperties, KeyboardEvent, forwardRef, useCallback, useState } from 'react';
import { backgroundErrorColor, black } from '../../constants/colors';

type Props = {
  disabled?: boolean;
  value?: number | null | undefined;
  onChange?: (newValue: number) => void;
  onBlur?: (newValue: number) => void;
  maxDecimal?: number;
  maxValue?: number;
  stripLeadingZeroes?: boolean;
  placeholder?: string;
  error?: boolean;
  name?: string;
  style?: CSSProperties;
  sx?: SxProps;
  suffix?: string;
};

export const getFormatNumberOptions = (maxDecimal = 2, decimalSeparator = '.', symbol = ''): CurrencySettings<string> => {
  return {
    precision: maxDecimal,
    format: '%v',
    decimal: decimalSeparator,
    thousand: '',
    symbol,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const InlineNumberInput = forwardRef<HTMLInputElement, Props>(({ style = {}, sx = {}, ...props }: Props, ref): JSX.Element => {
  const {
    value = 0,
    maxDecimal,
    maxValue = Number.MAX_SAFE_INTEGER,
    stripLeadingZeroes = true,
    onChange,
    onBlur,
    suffix,
    placeholder,
  } = props;
  const [focused, setFocused] = useState(false);

  const formatInternalValue = useCallback(
    (_value: number | null | undefined): string => {
      const isBelowMaxValue = maxValue ? (_value || 0) <= maxValue : true;
      return accounting.formatMoney(isBelowMaxValue ? _value || 0 : maxValue, getFormatNumberOptions(maxDecimal));
    },
    [maxValue, maxDecimal],
  );

  const unformat = (_value: string): number => accounting.unformat(_value, '.');

  const onNumberChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const number = unformat(event.target.value);
    onChange?.(number);
  };

  const onNumberBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const cleanedNumber = unformat(String(event.target.value));
    const _value = formatInternalValue(cleanedNumber);
    onChange?.(unformat(_value));
    onBlur?.(unformat(_value));
    setFocused(false);
  };

  return (
    <Box
      sx={{
        '&>input': {
          paddingLeft: '.5rem',
          border: '1px solid transparent',
          outline: 'none',
          width: '100%',
          backgroundColor: 'inherit',
          fontFamily: 'Inter',
          fontSize: '14px',
          lineHeight: '31px',
          color: 'black',
          ...(props.error &&
            !props.disabled && {
              color: (theme) => theme.palette.error.main,
              borderBottom: (theme) => `1px solid ${theme.palette.error.main}`,
              backgroundColor: backgroundErrorColor,
            }),
          ...(style || {}),
        },
        '&>input:focus': {
          border: `1px solid ${black}`,
        },
        ...sx,
      }}>
      <Cleave
        options={{
          numeral: true,
          numeralDecimalMark: '.',
          delimiter: '',
          stripLeadingZeroes,
          numeralDecimalScale: maxDecimal,
          prefix: suffix,
          tailPrefix: !!suffix,
        }}
        onFocusCapture={() => setFocused(true)}
        placeholder={placeholder}
        onChange={onNumberChange}
        onBlur={onNumberBlur}
        name={props.name ?? undefined}
        value={focused ? (typeof value === 'number' ? value : '') : formatInternalValue(value)}
        disabled={props.disabled}
        onKeyDown={preventDefault(maxValue)}
        aria-invalid={props.error}
      />
    </Box>
  );
});

const getNewValue = (eventKey: string, value: string, selectionStart: number, selectionEnd: number) => {
  let newValue = value;
  const valueArray = newValue.split('');
  const selectionLength = selectionEnd - selectionStart;
  if (eventKey === 'Backspace') {
    if (selectionLength === 0 && selectionStart > 0) valueArray.splice(selectionStart - 1, selectionLength + 1);
    else valueArray.splice(selectionStart, selectionLength);
  } else if (eventKey === 'Delete') {
    if (selectionLength === 0) valueArray.splice(selectionStart, selectionLength + 1);
    else valueArray.splice(selectionStart, selectionLength);
  } else {
    valueArray.splice(selectionStart, selectionLength, eventKey);
  }
  newValue = valueArray.join('');
  return newValue;
};

const numberKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', '-'];
const preventDefault =
  (maxValue = Number.MAX_SAFE_INTEGER) =>
  (event: KeyboardEvent<HTMLInputElement>) => {
    const { value, selectionStart, selectionEnd } = event.currentTarget;

    const newValue = parseFloat(getNewValue(event.key, value, selectionStart || 0, selectionEnd || 0));
    if (!Number.isNaN(newValue)) {
      const isExceedingMaxValue = newValue > maxValue;
      const isBelowZero = newValue < 0;
      if ((isExceedingMaxValue || isBelowZero) && numberKeys.includes(event.key)) event.preventDefault();
    }
  };
