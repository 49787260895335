const information = {
  title: 'Information',
  date: 'Date',
  client: "Client's name",
  siteName: "Site's name",
  siteAddress: "Site's address",
  workType: 'Work type',
  otNumber: 'OT Num',
  operator: 'Operator',
  unitNumber: 'Unit Num',
  gtNumber: 'GT Num',
};

const serviceHours = {
  addButtonLabel: 'Add',
  fromBiggerThanToError: 'From cannot be bigger than to',
  title: 'Service Hours',
  overlapTimeRangeError: 'Overlapping time ranges',
  header: {
    from: 'From',
    to: 'To',
    activity: 'Activity',
    single: 'Sin.',
    single_tip: 'Single',
    singleAndAHalf: 'Half',
    singleAndAHalf_tip: 'Half',
    double: 'Dou.',
    double_tip: 'Double',
    dispatch: 'Dist.',
    dispatch_tip: 'Distribution',
    quantity: 'Qty',
    quantity_tip: 'Quantity',
  },
  options: {
    lunchBreak: 'Lunch',
    serviceHour: 'Bill.',
  },
  craneService: 'Crane service',
  noData: 'No service hours',
};

const timeRangeSection = {
  from: 'From',
  to: 'To',
  total: 'Total',
  comment: 'Comment',
};

const detailNavbar = {
  informations: 'Informations',
  validation: 'Validation',
};

const exportButton = {
  editingActive: 'Cannot export while editing',
  unfinishedContractAgreement: 'Impossible to export an unfinished contract agreement',
  title: 'Export',
  exportOriginal: 'Export original',
  exportLatestVersion: 'Export latest version',
  exportErrorPdf: 'Error while exporting PDF',
};

const detail = {
  exportButton,
  title: 'Contract Agreement',
  noData: 'No contract agreement to display.',
  abbreviation: 'CA',
  edit: 'Edit',
  cancel: 'Cancel',
  save: 'Save',
  saveSuccessMessage: 'Modifications to contract agreement saved successfully',
  numberAlreadySet: 'Number already set',
  numberInvalid: 'Invalid number',
  numberLabel: 'Number',
  generateNumber: 'Generate',
};

const mechanicalFailures = {
  fromBiggerThanToError: 'From cannot be bigger than to',
  title: 'Mechanical Issues',
  overlapTimeRangeError: 'Overlapping time ranges',
  comment: 'Comment',
  noData: 'No mechanical issues',
};

const lunchBreaks = {
  fromBiggerThanToError: 'From cannot be bigger than to',
  title: 'Lunch Breaks',
  overlapTimeRangeError: 'Overlapping time ranges',
};

const preparations = {
  numberOfHours_one: '{{count}} hour',
  numberOfHours_other: '{{count}} hours',
  preparation: 'Work preparation',
  preparations: 'Work preparation',
  preparation_one: 'Preparation: {{count}} hour',
  preparation_other: 'Preparation: {{count}} hours',
};

const additionalFees = {
  select: 'Select',
  noData: 'No Additional Fees',
  title: 'Additional Fees',
  preparations,
  meals: 'Meals',
  taxis: 'Taxi Fees',
  parking: 'Parking Fees',
  travellingExpenses: 'Travelling Expenses',
  carAllowances: 'Car Allowance',
  pension: 'Total Pension: {{days}} day x {{people}} people',
  pensions: 'Total Pension: {{days}} days x {{people}} people',
  options: {
    meals: 'Meals',
    taxis: 'Taxi Fees',
    parking: 'Parking Fees',
    travellingExpenses: 'Travelling Expenses',
    carAllowances: 'Car Allowance',
    pensions: 'Pension',
    preparations: 'Work preparation',
  },
  edit: {
    pensions: {
      label: 'Pension',
      daysPerPerson: '(days per person)',
      numberOfPersons: 'Number of persons',
      unitOfTimeCount: 'Number of days',
      options: {
        Days: 'Days',
        Over2Weeks: 'Over 2 weeks',
      },
    },
    travellingExpenses: {
      to: 'to',
      kmTravelled: 'Km travelled',
    },
  },
  travelFee: {
    label: 'Travel Fee',
    editLabel: 'Travel Fee',
    none: 'No Travel',
    range49To72: '49 to 72 km',
    range73To88: '73 to 88 km',
    range89To119: '89 to 119 km',
    range120AndMore: '120 km +',
    siteTransfer: 'Site Transfer',
  },
  hour_one: '{{count}} hour',
  hour_other: '{{count}} hours',
  header: {
    fee: 'Fee',
    distribution: 'Dist.',
    distribution_tip: 'Distribution',
  },
};

const additionalTimes = {
  title: 'Additional Times',
  manTransport: 'Man Transport: {{hours}} hours x {{people}} people',
  manTime: 'Man Time: {{hours}} hours x {{people}} people',
  hours_one: '{{count}} hour',
  hours_other: '{{count}} hours',
  edit: {
    addAdditionalTimesLabel: 'Add an additional time',
    noBreakTimes: 'No Break',
    noLunchTimes: 'No Lunch',
    manTransport: {
      label: 'Man Transport',
      numberOfPersons: 'Number of persons',
      unitOfTimeCount: 'Number of hours',
    },
    manTime: {
      label: 'Man Time',
      numberOfPersons: 'Number of persons',
      unitOfTimeCount: 'Number of hours',
    },
  },
  overlapTimeRangeError: 'Overlapping time ranges',
  fromBiggerThanToError: 'From cannot be bigger than to',
  noTimeRangeError: 'No time range in list',
};

const comments = {
  title: 'Comments',
};

const accessories = {
  title: 'Accessories',
  noData: 'No Accessories used',
};

const overview = {
  client: 'Client',
  date: 'Date',
  representative: 'Project manager',
};

const crane = {
  unitNo: 'Unit No',
  operators: 'Crane operators',
};

const headers = {
  contractAgreementNumber: 'CA Number',
  ordreDeTravailNumber: 'OT Number',
  comment: 'Comments',
  salesBranch: 'Sales branch',
  dispatchBranchNumber: 'Dispatch branch',
  ...crane,
  client: overview.client,
  contractAgreementStatus: 'CA Status',
  date: overview.date,
  workType: 'Nature of the work',
  workDescription: 'Work description',
  representativeName: overview.representative,
  site: 'Worksite',
};

const list = {
  title: 'Contract Agreements',
  headers,
};

const signature = {
  title: 'Signature',
  noData: 'No signature',
};

const attachmentsSection = {
  title: 'Attachments',
  notUploaded: 'This attachment has not been uploaded yet',
  preview: 'View the attachment',
  download: 'Download the attachment',
};

const buttonToggle = {
  yes: 'Yes',
  no: 'No',
};

const numberUpdateWarningModal = {
  content_generate: 'This action cannot be reverted. A number will be automatically assigned to the current contract agreement.',
  content_set:
    'This action cannot be reverted. The number that you manually enterred will be assigned to the current contract agreement if valid.',
};

const root = {
  noOperatorName: 'Name is not Available',
  addButtonLabel: 'Add',
};

export const contractAgreement = {
  ...root,
  detail,
  detailNavbar,
  information,
  serviceHours,
  timeRangeSection,
  mechanicalFailures,
  additionalFees,
  additionalTimes,
  comments,
  accessories,
  list,
  lunchBreaks,
  signature,
  attachmentsSection,
  buttonToggle,
  numberUpdateWarningModal,
};
