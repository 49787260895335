import {
  CraneOperatorKind,
  CraneOperatorStartingPoint,
  FournisseurType,
  OrdreDeTravailAddendumKind,
  OrdreDeTravailAttachmentKind,
  OrdreDeTravailType,
  WeekType,
  WorkTypeCategory,
} from '../../generated/schemas';

const overview = {
  serviceCall: 'Appel de service',
  client: 'Client',
  date: 'Date',
  gtNumber: 'Numéro de GT',
  representative: 'Chargé de projet',
  representativeNo: 'No chargé de projet',
  title: 'Général',
  assignmentInformation: "Informations d'assignation",
  noSubcontracting: 'Aucun sous-traitant',
  noReplacement: 'Aucun remplacement de grue',
  secondServing: 'Deuxième tablée',
  requirements: 'Exigences client',
  noPo: '# Commande',
};

const crane = {
  capacity: 'Capacité requise',
  contractMinimum: 'Minimum au contrat',
  unitNo: 'No Unité',
  minimumHours: 'heure(s) minimum',
  operatorAssistants: 'Aide-grutier',
  operators: 'Grutiers',
  title: 'Grue',
  startingPointLabel: 'Point de départ',
  departure: 'Départ',
  arrival: 'Arrivée',
  startingPoint: {
    [CraneOperatorStartingPoint.Branch]: 'Succursale',
    [CraneOperatorStartingPoint.WorkSite]: 'Chantier',
  },
  operatorKind: {
    [CraneOperatorKind.AdditionalMan]: 'Homme additionnel',
    [CraneOperatorKind.Electrician]: 'Électricien',
    [CraneOperatorKind.Engineer]: 'Ingénieur',
    [CraneOperatorKind.Helper]: 'Aide-Grutier',
    [CraneOperatorKind.IndustrialDesigner]: 'Designer industriel',
    [CraneOperatorKind.Operator]: 'Opérateur',
    [CraneOperatorKind.Signalman]: 'Signaleur',
    [CraneOperatorKind.SiteSupervisor]: 'Superviseur de site',
    [CraneOperatorKind.TechnicalSupervisor]: 'Superviseur technique',
    [CraneOperatorKind.Welder]: 'Soudeur',
    [CraneOperatorKind.Trucker]: 'Chauffeur',
    [CraneOperatorKind.Mechanic]: 'Mécanicien',
    [CraneOperatorKind.Rigger]: 'Rigger',
    [CraneOperatorKind.ReplacedOperator]: 'Opérateur remplacé',
  },
};

const attachments = {
  title: 'Pièces jointes',
  displayName: 'Nom',
  type: 'Type',
  add: 'Ajouter',
  kind: {
    [OrdreDeTravailAttachmentKind.AssemblySequence]: "Séquence d'assemblage",
    [OrdreDeTravailAttachmentKind.Certifications]: 'Attestation',
    [OrdreDeTravailAttachmentKind.Charters]: 'Charte',
    [OrdreDeTravailAttachmentKind.CompetenceCards]: 'Carte de compétence',
    [OrdreDeTravailAttachmentKind.GroundPressureCalculations]: 'Calcul de la pression au sol',
    [OrdreDeTravailAttachmentKind.InsuranceDeclaration]: "Déclaration d'assurance",
    [OrdreDeTravailAttachmentKind.LiftingPlan]: 'Plan de levage',
    [OrdreDeTravailAttachmentKind.ListOfTransports]: 'Liste des Transports',
    [OrdreDeTravailAttachmentKind.Location]: 'Emplacement',
    [OrdreDeTravailAttachmentKind.Others]: 'Autre',
    [OrdreDeTravailAttachmentKind.ParkingPermit]: 'Permis de stationnement',
    [OrdreDeTravailAttachmentKind.Permit]: 'Permis',
    [OrdreDeTravailAttachmentKind.Photos]: 'Photo',
    [OrdreDeTravailAttachmentKind.ProjectDocuments]: 'Document de projet',
    [OrdreDeTravailAttachmentKind.RouteSpecifications]: "Spécification de l'itinéraire",
    [OrdreDeTravailAttachmentKind.SignalingPermit]: 'Permis de signalisation',
    [OrdreDeTravailAttachmentKind.StabilityStudy]: 'Étude de stabilité',
    [OrdreDeTravailAttachmentKind.TechnicalDrawings]: 'Dessin technique',
    [OrdreDeTravailAttachmentKind.TransportPermit]: 'Permis de transport',
    [OrdreDeTravailAttachmentKind.TravelSpecifications]: 'Spécification de voyage',
    [OrdreDeTravailAttachmentKind.WorkMethodEventSealedByEngineer]: 'Méthode de travail (événement) scellée par ingénieur',
  },
  noData: 'Aucune pièce jointe',
};

const addenda = {
  title: 'Addenda',
  description: 'Description',
  type: 'Type',
  add: 'Ajouter',
  kind: {
    [OrdreDeTravailAddendumKind.Archived]: 'Archivée',
    [OrdreDeTravailAddendumKind.ClientOrWorksite]: 'Client ou Chantier',
    [OrdreDeTravailAddendumKind.CraneOperator]: 'Grutier',
    [OrdreDeTravailAddendumKind.Dispatching]: 'Répartition',
    [OrdreDeTravailAddendumKind.Quote]: 'Citation',
    [OrdreDeTravailAddendumKind.InternalNote]: 'Note interne',
    [OrdreDeTravailAddendumKind.Invoicing]: 'Facturation',
    [OrdreDeTravailAddendumKind.SalesSupervisor]: 'Superviseur des ventes',
    [OrdreDeTravailAddendumKind.SupervisionNote]: 'Note de supervision',
  },
  noData: 'Aucun addenda',
};

const workInfo = {
  accessories: 'Accessoires à emporter',
  boomLength: 'Longueur de la flèche',
  counterweight: 'Contrepoids',
  description: 'Description du travail',
  jibLength: 'Longueur de la fléchette',
  maxWeightLift: 'Poids de la charge',
  offset: 'Offset',
  title: 'Travail',
  workRadius: 'Rayon de travail',
  craneType: 'Type de grue',
};

const weekType = {
  [WeekType.FourDaysTenHours]: 'Standard 4j x 10h',
  [WeekType.FourDaysTenHoursOneDayFiveHours]: 'Standard 4j x 10h + 1j x 5h',
  [WeekType.FiveDaysEightHours]: 'Standard 5j x 8h',
  [WeekType.FiveDaysNineHours]: 'Standard 5j x 9h',
  [WeekType.FiveDaysTenHours]: 'Standard 5j x 10h',
};

const workTypeCategory = {
  [WorkTypeCategory.Airports]: 'Aéroports',
  [WorkTypeCategory.Landscaping]: 'Aménagement paysager',
  [WorkTypeCategory.Antennas]: 'Antennes',
  [WorkTypeCategory.AqueductsSewer]: 'Aqueduc et Égouts',
  [WorkTypeCategory.Dams]: 'Barrages',
  [WorkTypeCategory.RetentionBasins]: 'Bassin de rétention',
  [WorkTypeCategory.Blade]: 'Roulements de pales',
  [WorkTypeCategory.Cages]: 'Cage de déménagement',
  [WorkTypeCategory.PowerPlants]: 'Centrales',
  [WorkTypeCategory.Chambers]: 'Chambres',
  [WorkTypeCategory.Demolition]: 'Démolition',
  [WorkTypeCategory.Distribution]: 'Distribution',
  [WorkTypeCategory.Pruning]: 'Émondage',
  [WorkTypeCategory.Excavation]: 'Excavation',
  [WorkTypeCategory.Gearboxes]: 'Boîte de vitesses',
  [WorkTypeCategory.Generator]: 'Génératrice',
  [WorkTypeCategory.Lifting]: "Levage d'équipement",
  [WorkTypeCategory.Lines]: 'Lignes',
  [WorkTypeCategory.Accessory]: "Location d'accessoires",
  [WorkTypeCategory.NonOperated]: 'Location non-opérée',
  [WorkTypeCategory.Hand]: 'Main',
  [WorkTypeCategory.Handling]: 'Manutention',
  [WorkTypeCategory.Modules]: 'Modules',
  [WorkTypeCategory.Gondola]: 'Nacelle/Panier suspendu',
  [WorkTypeCategory.NewConstruction]: 'Nouvelle construction/Agrandissement',
  [WorkTypeCategory.Bridges]: 'Ponts/Viaducs',
  [WorkTypeCategory.Substations]: 'Postes/Centrales',
  [WorkTypeCategory.Pylons]: 'Pylônes',
  [WorkTypeCategory.Docks]: 'Quais',
  [WorkTypeCategory.Refurbishment]: 'Réfection',
  [WorkTypeCategory.Rotor]: 'Rotor',
  [WorkTypeCategory.RoadsRails]: 'Routes/Rails/Métro',
  [WorkTypeCategory.Shelters]: 'Abris',
  [WorkTypeCategory.Towers]: 'Tours',
  [WorkTypeCategory.Transformers]: 'Transformateurs',
  [WorkTypeCategory.Transport]: 'Transport',
  [WorkTypeCategory.VentilationPlumbingElectrical]: 'Ventilation/Plomberie/Électricité',
};

const worksite = {
  address: 'Adresse du chantier',
  arrivalDateTime: "Date d'arrivée",
  callReceivedFrom: 'Appel reçu de',
  contact: 'Sur le chantier voir',
  departureDateTime: 'Date de départ',
  name: 'Nom du chantier',
  phone: 'Cell. chantier',
  title: 'Chantier',
  readyDateTime: 'Date prête',
  workType: 'Nature du travail',
  workTypeCategory: 'Catégorie de travail',
  estimatedDurationInDays: 'Durée (jrs)',
  estimatedDurationInHours: 'Durée (heures)',
  estimatedDurationInDaysUnit: 'jrs',
  estimatedDurationInHoursUnit: 'heures',
  weekType: 'Horaire travail',
  select: 'Sélectionner une date',
  requirements: 'Exigences chantier',
  establishedSchedule: 'Horaire établi',
};

const fournisseur = {
  add: 'Ajouter',
  fournisseurs: 'Fournisseurs',
  name: 'Fournisseur',
  numeroDeCommandeAchat: '#CA',
  noData: 'Aucun fournisseur',
  description: 'Description',
  departure: 'Départ',
  subcontractor: 'Sous-Traitant',
  billingCode: 'Code facturation',
  planned: 'Planifié',
  type: {
    label: 'Type',
    [FournisseurType.Transporter]: 'Transport',
    [FournisseurType.Other]: 'Autre',
  },
};

const advancementFollowup = {
  title: "Suivi de l'avancement",
  contractAgreement: 'Contrat de service',
  timesheet: 'Carte de temps',
  codesDeFacturation: 'Codes de facturation',
  addTimesheetButton: '+ Carte de temps',
};

const addTimesheetModal = {
  title: 'Créer une carte de temps',
  subtitle: 'Sélectionner le grutier ainsi que le poste occupé.',
  employee: 'Grutier',
  kind: 'Role',
  cancel: 'Annuler',
  add: 'Créer',
  error: "L'ajout de la carte de temps a échoué.",
};

const headers = {
  number: "Numéro de l'OT",
  serviceCall: 'Appel de service',
  branch: 'Succursale de répartition',
  unitNo: crane.unitNo,
  client: overview.client,
  operators: crane.operators,
  documentStatus: "Statut de l'OT",
  date: overview.date,
  workType: 'Nature du Travail',
  contractMinimum: 'Minimum du Contrat',
  requiredCapacity: 'Capacité requise',
  workDescription: 'Description du Travail',
  maximumWeightToLift: 'Poids Max. à Soulever',
  representativeName: 'Chargé de projet',
  site: 'Chantier',
};

const list = {
  title: 'Ordres de travail',
  headers,
};

const detail = {
  title: 'Ordre de travail',
  edit: 'Modifier',
  saveSuccessMessage: "Les modifications à l'ordre de travail ont été enregistrées avec succès",
  cancel: 'Annuler',
  save: 'Terminer',
  exportButton: 'Exporter',
  warnings: 'Avertissements',
  confirm: 'Confirmer',
  otStatusChangeWarning:
    'Le changement de statut aura pour effet de créer un contrat de service associé à l’ordre de travail. Désirez-vous continuer? Cette action est irréversible.',
  otStatusChangeError: 'Le changement de statut a échoué. Veuillez réessayer.',
  otContinuityUpdateWarning: 'La modification du champs sera appliquée sur tous les OTs qui ont le même numéro.',
};

const detailTabs = {
  informationsTab: 'Informations',
  contractAgreementTab: 'Contrats de service',
};

const requiredValidation = {
  arrivalDateTime: `La ${worksite.arrivalDateTime.toLowerCase()} est requise.`,
  callReceivedFrom: `L'${worksite.callReceivedFrom.toLowerCase()} est requis.`,
  date: `La ${overview.date.toLowerCase()} est requise.`,
  departureDateTime: `La ${worksite.departureDateTime.toLowerCase()} est requise.`,
  workDescription: `La ${workInfo.description.toLowerCase()} est requise.`,
  fournisseurName: `Le ${fournisseur.name.toLowerCase()} est requis.`,
  fournisseurDescription: `La ${fournisseur.description.toLowerCase()} est requise.`,
  fournisseurBillingCode: `Le ${fournisseur.billingCode.toLowerCase()} est requis.`,
  fournisseurNumeroDeCommandeAchat: `Le ${fournisseur.numeroDeCommandeAchat.toLowerCase()} est requis.`,
  addendumKind: `Le ${addenda.type.toLowerCase()} est requis.`,
  addendumDescription: `La ${addenda.description.toLowerCase()} est requise.`,
  estimatedDurationInHours: `La ${worksite.estimatedDurationInHours.toLowerCase()} est requise.`,
  representative: `Le ${overview.representative.toLowerCase()} est requis.`,
  worksite: `Le ${worksite.title.toLowerCase()} est requis.`,
  workType: `La ${worksite.workType.toLowerCase()} est requise.`,
  purchaseOrder: `Le ${overview.noPo.toLowerCase()} est requis.`,
  attachmentName: `Le ${attachments.displayName.toLowerCase()} est requis.`,
};

const yupTestValidation = {
  arrivalBeforeDepartureTime: `${worksite.arrivalDateTime} doit suivre ${worksite.departureDateTime}`,
  readyBeforeArrivalTime: `${worksite.readyDateTime} doit suivre ${worksite.arrivalDateTime}`,
  estimatedDurationInHoursInvalidInterval: `${worksite.estimatedDurationInHours} doit avoir des intervalles de 0,5`,
};

const requirementsModal = {
  close: 'Fermer',
};

const billingRequirementDescriptionModal = {
  title: 'Exigences de facturation',
  openButtonLabel: 'Exigences de facturation',
};

const ordreDeTravailTypes = {
  accessoryRental: "Location d'accessoires",
  bare: 'Grue non-opérée',
  laborRental: "Location de main d'oeuvre",
  laborRentalWithCrane: "Location de main d'oeuvre",
  craneOperatorLaborRental: "Location de main d'oeuvre",
  liftingPlan: 'Plan de levage',
  liftingTest: 'Test de levage',
  operatedHourly: "Grue opérée (à l'heure)",
  operatedMonthly: 'Grue opérée (mensuelle)',
  rollingEquipment: 'Matériel roulant',
} satisfies Record<OrdreDeTravailType, string>;

export const ordreDeTravail = {
  unitFeet: 'pieds',
  unitPounds: 'lbs',
  unitTons: 'tonnes',
  submitted: 'Soumis',
  advancementFollowup,
  detail,
  detailTabs,
  crane,
  list,
  overview,
  workInfo,
  worksite,
  weekType,
  workTypeCategory,
  requiredValidation,
  yupTestValidation,
  addTimesheetModal,
  requirementsModal,
  attachments,
  addenda,
  fournisseur,
  billingRequirementDescriptionModal,
  ordreDeTravailTypes,
};
