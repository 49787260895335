import axios, { AxiosError } from 'axios';
import { DefinedInitialDataOptions, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Path } from '../../paths';
import { useLoaderData } from 'react-router-dom';
import { OrdreDeTravailScheduleLoaderData } from '../loaders/ordreDeTravailScheduleLoader';

export const getOrdreDeTravailNumberQueryKey = (id: string | undefined): QueryKey => [Path.ValidationsPage, id];

export const ordreDeTravailNumberQueryOptions = (
  id: string | undefined,
): Pick<DefinedInitialDataOptions<string, AxiosError>, 'queryKey' | 'queryFn' | 'gcTime' | 'staleTime'> => ({
  queryKey: getOrdreDeTravailNumberQueryKey(id),
  queryFn: async () => axios.get<string>(`/ordre-de-travail/${id}/number`).then((response) => response.data),
  staleTime: 5 * 60 * 1000,
  gcTime: 10 * 60 * 1000,
});

export const useGetOrdreDeTravailNumber = (contractAgreementId: string | undefined): UseQueryResult<string, AxiosError> => {
  const { otNumber } = useLoaderData() as OrdreDeTravailScheduleLoaderData;
  return useQuery({
    ...ordreDeTravailNumberQueryOptions(contractAgreementId),
    initialData: otNumber?.data,
    enabled: !!contractAgreementId,
  });
};
